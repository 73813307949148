import React, { useEffect, useState } from 'react'
import { Button, Result } from 'antd'
import { Link } from 'gatsby'

import Logo from '../images/svg/cb_log_2024_orange_white.svg'
const NotFoundPage = ({ location }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 5000)
    return () => clearTimeout(timer)
  }, [])

  if (loading) {
    return <></>
  } else {
    return (
      <div className="flex w-full h-screen justify-center flex-col items-center">
        <div >
          <Link to="/">
            <Logo width="200" height="50"/>
          </Link>
        </div>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <>
              <Button>
                <Link to="/" type="primary">
                  Go Home
                </Link>
              </Button>
              <Button>
                <Link to="/dashboard" type="primary">
                  Go to Dashboard
                </Link>
              </Button>
            </>
          }
        />
      </div>
    )
  }
}

export default NotFoundPage
